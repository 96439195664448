import { isNil } from 'lodash';

export const lowercase = (param: string): string => param.toLocaleLowerCase();

export const uppercase = (param: string): string => param.toLocaleUpperCase();

export const siren = (string: string): string =>
  string.match(/.{1,3}/g).join(' ');

export const externalVatToInternal = (vat: string): string =>
  vat?.toUpperCase().replace(/[^A-Z0-9]/g, '');

/**
 * Convert VAT number from internal format to external format
 * @param vat VAT with internal format
 * @returns VAT with external format
 * formatVatToExternal('FR12234567890') => 'FR12 234 567 890'
 */
export const internalVatToExternal = (vat: string): string => {
  const output = vat.split('');

  for (let i = 4; i < output.length; i = i + 4) {
    output.splice(i, 0, ' ');
  }

  return output.join('');
};

export const getString = (value: unknown) => {
  if (isNil(value)) return;

  return `${value}`;
};

export const renderBooleanValue = (value: string | boolean) => {
  if (!value) return null;

  return [true, 'true'].includes(value) ? true : false;
};
